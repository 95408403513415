<template>
  <div>
    <v-dialog v-model="showView" persistent v-if="showView">
      <contract-view
        ref="textEditPerson"
        :objEdit="item"
        :personEdit="personEdit"
        @close="close()"
      />
    </v-dialog>
    <s-crud
      :single-row="false"
      title="CONTRATO"
      :config="config"
      :filter="filter"
      add-no-function
      ref="gridAgreement"
      @rowSelected="rowSelected($event)"
      add
      @add="addWorker()"
      searchInput
      @doubleClick="assignWorker($event)"
    >
      <!-- <template v-slot:options>
        <v-tooltip bottom="" v-if="item != null ? true : false">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text black @click="printContract(item)" small>
              <v-icon style="font-size: 14px">mdi-printer-eye</v-icon>
            </v-btn>
          </template>
          <span>Ver Contrato</span>
        </v-tooltip>
        <v-tooltip bottom="">
          <template v-slot:activator="{ on }">
            <v-btn
              text
              v-on="on"
              small
              elevation="0"
              @click="viewContract(item)"
            >
              <v-icon style="font-size: 16px">mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Ver trabajador</span>
        </v-tooltip>
      </template> -->
      <template v-slot:filter>
        <v-container>
          <v-row>
            <v-col>
              <s-date
                label="Fecha Inicio"
                v-model="filter.AgtBeginDate"
                v-if="!filter.AgtAll"
              ></s-date>
              <s-text
                label="Fecha Inicio"
                v-model="filter.AgtBeginDate"
                :disabled="filter.AgtAll === 1"
                v-else
              ></s-text>
            </v-col>
            <v-col>
              <s-date
                label="Fecha Fin"
                v-model="filter.AgtEndDate"
                v-if="!filter.AgtAll"
              ></s-date>
              <s-text
                label="Fecha Fin"
                v-model="filter.AgtEndDate"
                :disabled="filter.AgtAll === 1"
                v-else
              ></s-text>
            </v-col>
            <span>
              <v-switch
                label="Todos"
                v-model="filter.AgtAll"
                dense
                hide-details
                :false-value="0"
                :true-value="1"
              />
            </span>
            <v-col>
              <s-select-definition
                label="Estado"
                :def="1059"
                v-model="filter.AgtStatus"
                clearable
              ></s-select-definition>
            </v-col>
            <v-col>
              <s-select-definition
                label="Cat. Ocupacional"
                :def="1039"
                :dgr="4"
                v-model="filter.TypeOccupationalCategory"
                clearable
              ></s-select-definition>
            </v-col>
            <v-col>
              <s-select-area
                label="Area"
                v-model="filter.AreID"
              ></s-select-area>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:AgtStatus="{ row }">
        <v-chip x-small :color="row.AgtStatus == 1 ? 'info' : 'error'">
          {{ row.AgtStatus == 1 ? "Activo" : "Inactivo" }}
        </v-chip>
      </template>

      <template v-slot:AsgCheck="{ row }">
        <v-tooltip bottom="">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              text
              black
              color="success"
              @click="rowSelectedCheck(row)"
              x-small
            >
              <v-icon style="font-size: 14px">mdi-file-plus</v-icon>
            </v-btn>
          </template>
          <span>Agregar a Impresión Masiva</span>
        </v-tooltip>
      </template>

      <template v-slot:PstCritical="{ row }">
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn 
						v-bind="attrs"
						v-on="on"
						icon
						x-small
						color="red"
						>
							<v-icon>mdi-folder-multiple-outline</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item 
						@click="rowSelectedPstCritical(row)"
            v-if="row.PstIsBascCritic >= 3"
						>
							<v-list-item-icon>
								<v-icon>mdi-file-document</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Imprimir Designación de Puesto Critico</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item 
						@click="rowSelectedPstSSTCritical(row)"
						>
							<v-list-item-icon>
								<v-icon>mdi-file-document</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Imprimir Recomendaciones SST</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>

      <template v-slot:PstIsBascCritic="{ row }">
        <v-chip
          x-small
          :color="
            row.PstIsBascCritic == 1 || row.PstIsBascCritic == 0
              ? 'white'
              : row.PstIsBascCritic == 2
              ? 'primary'
              : row.PstIsBascCritic == 3
              ? 'warning'
              : 'error'
          "
          style="margin:0px;"
          >{{
            row.PstIsBascCritic == 0
              ? "No Definido"
              : row.PstIsBascCriticName
          }}
        </v-chip>
      </template>
    </s-crud>
    <br />
    <v-card>
      <s-toolbar
        label="IMPRESIÓN MASIVA"
        dark
        color="#8e8f91"
        print
        @print="openDialogDocument()"
        remove
        @removed="deleteItem()"
      ></s-toolbar>
      <v-data-table
        item-key="AgtID"
        dense
        :items="itemCheck"
        :headers="headers"
      >
        <template v-slot:[`item.AsgCheckTable`]="{ item }">
          <v-tooltip bottom="">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                text
                black
                color="#ff0000"
                @click="rowSelectedCheckDelete(item)"
                x-small
              >
                <v-icon style="font-size: 14px">mdi-bookmark-remove</v-icon>
              </v-btn>
            </template>
            <span>Eliminar de Impresión Masiva</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-if="dialogPdfContractPorTemporada"
      v-model="dialogPdfContractPorTemporada"
      fullscreen
      persistent
    >
      <PdfContractPorTemporada
        :data="PdfSelectedContract"
        @close="dialogPdfContractPorTemporada = false"
      ></PdfContractPorTemporada>
    </v-dialog>
    <v-dialog
      v-if="dialogPdfContractPorIncrementoActividades"
      v-model="dialogPdfContractPorIncrementoActividades"
      fullscreen
      persistent
    >
      <PdfContractPorIncrementoActividades
        :data="PdfSelectedContract"
        @close="dialogPdfContractPorIncrementoActividades = false"
      ></PdfContractPorIncrementoActividades>
    </v-dialog>
    <v-dialog
      v-if="dialogPdfContractPorServicioEspecifico"
      v-model="dialogPdfContractPorServicioEspecifico"
      fullscreen
      persistent
    >
      <PdfContractPorServicioEspecifico
        :data="PdfSelectedContract"
        @close="dialogPdfContractPorServicioEspecifico = false"
      ></PdfContractPorServicioEspecifico>
    </v-dialog>
    <v-dialog
      v-if="dialogPdfContractMasivo"
      v-model="dialogPdfContractMasivo"
      fullscreen
      persistent
    >
      <PdfContractMasivo
        :dataMasiva="PdfSelectedContract"
        @close="dialogPdfContractMasivo = false"
      ></PdfContractMasivo>
    </v-dialog>
    <div
      v-if="dialogPdfContractMasivo"
      style="
        position: fixed;
        bottom: 10px;
        right: 10px;
        z-index: 10000;
        width: 50px;
        height: 50px;
      "
    >
      <v-btn x-small fab color="success"><v-icon>mdi-rocket</v-icon></v-btn>
    </div>
    <v-dialog
      v-if="openPdfCritical"
      v-model="openPdfCritical"
      width="unset"
      persistent
    >
      <pdf-critical-print
        :itemSelect="itemsSearch"
        @close="openPdfCritical = false"
      >
      </pdf-critical-print>
    </v-dialog>

    <v-dialog v-if="openPdfSSTCritical" v-model="openPdfSSTCritical" width="unset" persistent>
			<div style="position: fixed; bottom: 10px; right: 10px; z-index: 10000; width: 130px; height: 65px;">
				<s-finger-print
				text="Escanear"
				color="success"
				:btnProps="{ 'x-small': true, fab: false }"
				@fingerprintSaved="handleFingerprint">
				</s-finger-print>
			</div>
      <pdf-sst-critical
        :items="itemsSSTCritical"
        :itemsst="itemsst"
        :fingerprintImage="fingerprintImage"
        @close="openPdfSSTCritical = false">
      </pdf-sst-critical>
    </v-dialog>

    <v-dialog v-model="DialogDocument" v-if="PdfSelectedContract != null" persistent width="500">
      <s-toolbar
        label="Documentos"
        close
        @close="DialogDocument = false"
        color="#BAB6B5"
        dark
      ></s-toolbar>
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="12" class="text-left">
              <h5>Seleccione los documentos a descargar</h5>
            </v-col>
          </v-row>
          <!-- Lista de opciones -->
          <v-row>
            <v-col cols="12" class="s-col-form">
              <v-list dense>
                <v-list-item 
                  v-for="item in filteredDocumentOptions" 
                  :key="item.value" 
                  :class="{'selected-item': selectedDocuments.includes(item.value)}"
                  @click="toggleSelection(item.value)"
                  class="custom-item"
                >
                  <v-list-item-action>
                    <v-checkbox 
                      :input-value="selectedDocuments.includes(item.value)"
                      @click.stop="toggleSelection(item.value)"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn color="primary" @click="mergeAndDownloadPDFs()">
                <v-icon left>mdi-download</v-icon>
                Descargar Seleccionados
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogMissingSST" persistent width="520">
      <s-toolbar
        label="Registros sin información de SST"
        close
        @close="dialogMissingSST = false"
        color="#BAB6B5"
        dark
      ></s-toolbar>
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="12" class="text-left">
              <h5>Detalles de contratos</h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table :headers="headersMissing" :items="missingSSTRecords" dense>
                <template v-slot:item.AsgCheckTable="{ item }">
                  <v-icon small color="red">mdi-alert-circle</v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <AlertSunshine :dialog="dialog" />
  </div>
</template>

<script>
import _sContractService from "@/services/HumanResource/HmnContractService.js";
import sTextarea from "@/components/Utils/sTextarea.vue";
import ContractView from "@/views/HumanResource/ContractView.vue";
import SSelectArea from "@/components/Utils/SSelectArea.vue";
import Service from "@/services/General/PersonService";
import _sAdmonition from "@/services/HumanResource/AdmonitionService";
import PdfContractPorTemporada from "@/views/HumanResource/PdfContract/PdfContractPorTemporada.vue";
import PdfContractPorIncrementoActividades from "@/views/HumanResource/PdfContract/PdfContractPorIncrementoActividades.vue";
import PdfContractPorServicioEspecifico from "@/views/HumanResource/PdfContract/PdfContractPorServicioEspecifico.vue";
import PdfContractMasivo from "@/views/HumanResource/PdfContract/PdfContractMasivo.vue";
import _sWorkerTabService from "@/services/HumanResource/HmnWorkerTabService.js";
import PdfCriticalPrint from "@/views/HumanResource/ContractDocument/HmnPrintCritical.vue";
import _sGenParam from "../../../services/General/ParameterService";
import PdfSstCritical from './../ContractDocument/HmnPrintSSTCritical.vue';
import _sDangerSSTService from "@/services/HumanResource/HmnDangerSSTService";
import sFingerPrint from "@/components/Utils/RecursosHm/sFingerPrint";

export default {
  components: {
    sTextarea,
    SSelectArea,
    ContractView,
    PdfContractPorTemporada,
    PdfContractPorIncrementoActividades,
    PdfContractPorServicioEspecifico,
    PdfContractMasivo,
    PdfCriticalPrint,
    PdfSstCritical,
		sFingerPrint
  },
  data() {
    return {
      dialog: false,
      item: [],
      itemCheck: [],
      PdfSelectedContract: {},
      dialogPdfContractPorTemporada: false,
      dialogPdfContractPorIncrementoActividades: false,
      dialogPdfContractPorServicioEspecifico: false,
      dialogPdfContractMasivo: false,
      showEdit: false,
      showAdd: false,
      showView: false,
      typeperson: 1,
      isValidation: 0,
      personEdit: null,
      itemsSearch: [],
      openPdfCritical: false,
      itemsSSTCritical: [],
      openPdfSSTCritical: false,
      itemsst: [],
      fingerprintImage: null,
      DialogDocument: false,
      selectedDocuments: [],
      dialogMissingSST: false,
      missingSSTRecords: [],
      filter: {
        AgtAll: 0,
        AgtStatus: 1,
        IsAdmin: this.$fun.isAdmin() ? 1 : 0,
        AreID: null,
        TypeOccupationalCategory: null,
        AgtBeginDate: null,
        AgtEndDate: null,
      },
      config: {
        model: {
          AgtID: "ID",
          NtpBirthDate: "date",
          Action: "Action",
          AgtStatus: "string",
          DisProDep: "",
          AgtBeginDate: "date",
          AgtEndDate: "date",
          actions: "",
          WkrAdmonition: "string",
          AsgCheck: "",
          PstCritical: "",
          PstIsBascCritic: "",
        },
        service: _sContractService,
        headers: [
          // { text: "",                     value: "AsgCheck", width: 30 },
          // { text: "",                     value: "PstCritical", width: 30 },
          { text: "ID"                              , value: "AgtID"              },
          { text: "N° Doc"                          , value: "PrsDocumentNumber"  },
          { text: "Apellidos y Nombres Completos"   , value: "NtpFullName"        },
          { text: "Cargo"                           , value: "PstName"            },
          { text: "Area"                            , value: "AreName"            },
          { text: "Inicio de Contrato"              , value: "AgtBeginDate"       },    
          // { text: "Fin de Contrato"                 , value: "AgtEndDate", width: 30 },
          { text: "Nivel de Criticidad"             , value: "PstIsBascCritic"		},		
          { text: "Estado"                          , value: "AgtStatus"          },
        ],
      },
      extension: false,
      renovation: false,
      AsgCheckTable: "",
      headers: [
        { text: "ID",                               value: "AgtID" },
        { text: "Nombres y Apellidos Completos",    value: "NtpFullName" },
        { text: "Cargo",                            value: "PstName" },
        { text: "",                                 value: "AsgCheckTable", width: 30 },
      ],
      headersMissing: [
        { text: "ID",                               value: "AgtID" },
        { text: "Nombres y Apellidos Completos",    value: "NtpFullName" },
        { text: "Área",                             value: "AreName" },
        { text: "Cargo",                            value: "PstName" },
        { text: "",                                 value: "AsgCheckTable", width: 30 },
      ],
      documentOptions: [
        { text: "Contrato", value: "contract" },
        { text: "Designación del puesto Crítico", value: "critical" },
        { text: "Recomendaciones SST", value: "sst" },
      ],
    };
  },
  watch: {
    filter() {},
  },
  computed: {
    filteredDocumentOptions() {
      let options = [...this.documentOptions];
      
      // Verificar si hay algún contrato con PstIsBascCritic >= 3
      const hasCritical = this.itemCheck.some(item => item.PstIsBascCritic >= 3);

      // Si ninguno de los contratos tiene PstIsBascCritic >= 3, eliminar "critical"
      if (!hasCritical) {
        options = options.filter(item => item.value !== 'critical');
      }

      return options;
    }
  },
  methods: {
    initialize() {},
    rowSelected(item) {
      if (item !== null) {
        this.item = item;
      } else {
        this.item = null;
      }
    },
    toggleSelection(value) {
      const index = this.selectedDocuments.indexOf(value);
      if (index === -1) {
        this.selectedDocuments.push(value);
      } else {
        this.selectedDocuments.splice(index, 1);
      }
    },
    addWorker() {
      this.item.forEach((element) => {
        if (this.itemCheck.find((x) => x == element) == null) this.itemCheck.push(element);
      });
    },
    assignWorker(worker) {
      if (this.itemCheck.find((x) => x == worker) == null) this.itemCheck.push(worker);
    },
    rowSelectedCheck(item) {
      if (item !== null) {
        const index = this.itemCheck.findIndex((el) => el.AgtID === item.AgtID);
        if (index === -1 && item.AgtStatus == 1) {
          this.itemCheck.push(item);
        } else if (index !== -1) {
          this.$fun.alert(
            "El contrato seleccionado ya se encuentra agregado",
            "warning"
          );
        } else {
          this.$fun.alert("El contrato seleccionado no está activo", "warning");
        }
      }
    },
    rowSelectedPstCritical(item) {
      _sWorkerTabService
        .list(item.PrsID, this.$fun.getUserID())
        .then((response) => {
          this.itemsSearch = response.data[0];
          this.openPdfCritical = true;
        });
    },
    rowSelectedPstSSTCritical(item) {
      const obj = {
        AreID : item.AreID,
        PstID : item.PstID
      }
      _sDangerSSTService.list(obj, this.$fun.getUserID()).then(response => {
        if(response.data == null){
          this.$fun.alert("No se encontraron resultados de SST","warning");
          return;
        }
        if (response.status == 200) {
          this.itemsSSTCritical = item;
          this.fingerprintImage = null;
          this.itemsst = response.data;
          this.openPdfSSTCritical = true;
        }
      })
    },
    handleFingerprint(image) {
      this.fingerprintImage = image;
    },
    rowSelectedCheckDelete(item) {
      if (item !== null) {
        const index = this.itemCheck.findIndex((el) => el.AgtID === item.AgtID);
        if (index !== -1) {
          this.itemCheck.splice(index, 1);
        }
      }
    },
    clickActionTxt(item) {
      if (item) {
        const textContent = [
          "",
          item.AgtID,
          item.PrsDocumentNumber,
          item.NtpFullName,
          item.NtpBirthDate,
          item.PadAddress,
          item.PadAddressReference,
          item.DisProDep,
        ].join("|");

        const blob = new Blob([textContent], { type: "text/plain" });

        // Crear un enlace para descargar el archivo
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "contrato.txt";

        link.click();
      } else {
        console.log("No hay datos disponibles para descargar");
      }
    },

    close() {
      this.showEdit = false;
      this.showView = false;
      this.showAdd = false;
      this.extension = false;
      this.renovation = false;
      this.$refs.gridAgreement.refresh();
    },
    openDialogDocument() {
      if(this.itemCheck.length == 0){
        this.$fun.alert("Debe seleccionar al menos un contrato", "warning");
        return;
      }
      this.DialogDocument = true;
    },
    async printMasiveContract() {
      //console.log("masivo", this.itemCheck);
      this.dialog = true;
      const items = [];
      for (const row of this.itemCheck) {
        if (!this.$fun.isAdmin()) {
          if (
            row.TypePosition == 1 ||
            row.TypePosition == 2 ||
            row.TypePosition == 3
          ) {
            if (!this.$fun.hasSpecialPermission("Nivel_1")) {
              this.$fun.alert(
                "Usted no tiene permisos para imprimir un contrato de personal ejecutivo",
                "warning"
              );
              return;
            }
          }

          if (
            row.TypePosition == 4 ||
            row.TypePosition == 5 ||
            row.TypePosition == 6
          ) {
            if (!this.$fun.hasSpecialPermission("Nivel_2")) {
              this.$fun.alert(
                "Usted no tiene permisos para imprimir un contrato de personal administrativo",
                "warning"
              );
              return;
            }
          }

          if (
            row.TypePosition == 7 ||
            row.TypePosition == 8 ||
            row.TypePosition == 9
          ) {
            if (!this.$fun.hasSpecialPermission("Nivel_3")) {
              this.$fun.alert(
                "Usted no tiene permisos para imprimir un contrato de personal operativo",
                "warning"
              );
              return;
            }
          }

          if (
            !this.$fun
              .getSecurity()
              .PayrollsAllow.some(
                (payroll) => payroll.TypePayroll === row.TypePayroll
              )
          ) {
            this.$fun.alert(
              "Usted no tiene permisos para imprimir este tipo de contrato",
              "warning"
            );
            return;
          }
        }
        // console.log(row);
        await _sContractService
          .searchContract(row.AgtID, this.$fun.getUserID())
          .then(async r => {
            if (r.status == 200) {
              //console.log("r.data:", r.data);
              var item = r.data[0];

              item.DangerSSTDetail = [];
              item.DangerSSTRecommendation = [];

              item.PstIsBascCritic = row.PstIsBascCritic;
              _sGenParam
                .search({ PrmName: "RH-FR-039" }, this.$fun.getUserID())
                .then((resp) => {
                  if (resp.status == 200) {
                    item.definitiveReview = this.definitiveReview;
                  }
                });

                const obj = {
                  AreID : row.AreID,
                  PstID : row.PstID
                }

                await _sDangerSSTService.list(obj, this.$fun.getUserID()).then(response => {
                  if(response.data == null){
                    this.$fun.alert("No se encontraron resultados de SST","warning");
                    return;
                  }
                  if (response.status == 200) {
                    item.itemsst = response.data;
                    item.DangerSSTDetail = response.data.DangerSSTDetail || [];
                    item.DangerSSTRecommendation = response.data.DangerSSTRecommendation || [];
                  }
                })

              if (typeof item.itemsFunciones === "string") {
                try {
                  item.itemsFunciones = JSON.parse(item.itemsFunciones);
                } catch (e) {
                  console.error("Error al parsear item.itemsFunciones:", e);
                }
              } else if (
                typeof item.itemsFunciones !== "object" ||
                item.itemsFunciones === null
              ) {
                console.error(
                  "item.itemsFunciones no es un JSON válido o no es una cadena JSON."
                );
              }
              items.push(item);
            }
          });
      }
      this.PdfSelectedContract = items;
      this.dialog = false;
      this.dialogPdfContractMasivo = true;
    },
    async mergeAndDownloadPDFs() {
      if(this.selectedDocuments == 0){
        this.$fun.alert("Debe seleccionar al menos una opción", "warning");
        return;
      }
      this.dialog = true;
      const items = [];
      this.missingSSTRecords = [];

      for (const row of this.itemCheck) {
        if (!this.$fun.isAdmin()) {
          if (
            row.TypePosition == 1 ||
            row.TypePosition == 2 ||
            row.TypePosition == 3
          ) {
            if (!this.$fun.hasSpecialPermission("Nivel_1")) {
              this.$fun.alert(
                "Usted no tiene permisos para imprimir un contrato de personal ejecutivo",
                "warning"
              );
              return;
            }
          }

          if (
            row.TypePosition == 4 ||
            row.TypePosition == 5 ||
            row.TypePosition == 6
          ) {
            if (!this.$fun.hasSpecialPermission("Nivel_2")) {
              this.$fun.alert(
                "Usted no tiene permisos para imprimir un contrato de personal administrativo",
                "warning"
              );
              return;
            }
          }

          if (
            row.TypePosition == 7 ||
            row.TypePosition == 8 ||
            row.TypePosition == 9
          ) {
            if (!this.$fun.hasSpecialPermission("Nivel_3")) {
              this.$fun.alert(
                "Usted no tiene permisos para imprimir un contrato de personal operativo",
                "warning"
              );
              return;
            }
          }

          if (
            !this.$fun
              .getSecurity()
              .PayrollsAllow.some(
                (payroll) => payroll.TypePayroll === row.TypePayroll
              )
          ) {
            this.$fun.alert(
              "Usted no tiene permisos para imprimir este tipo de contrato",
              "warning"
            );
            return;
          }
        }
        
        await _sContractService
          .searchContract(row.AgtID, this.$fun.getUserID())
          .then(async r => {
            if (r.status == 200) {

              var item = r.data[0];

              item.DangerSSTDetail = [];
              item.DangerSSTRecommendation = [];

              item.PstIsBascCritic = row.PstIsBascCritic;
              item.optionSelected = this.selectedDocuments;

              const response1 = await _sGenParam.search({ PrmName: "RH-FR-039" }, this.$fun.getUserID());
              if (response1.status == 200 && response1.data != null) {
                //console.log(response1.data.PrmValue);
                item.definitiveReview = response1.data.PrmValue;
              }

              const response2 = await _sGenParam.search({ PrmName: "ST-FR-062" }, this.$fun.getUserID());
              if (response2.status == 200 && response2.data != null) {
                item.definitiveReviewSST = response2.data.PrmValue;
              }

              if(this.selectedDocuments.includes('sst')){
                const obj = {
                  AreID : row.AreID,
                  PstID : row.PstID
                }

                await _sDangerSSTService.list(obj, this.$fun.getUserID()).then(response => {
                  if (response.data == null) {
                    this.missingSSTRecords.push(row);
                    return;
                  }
                  if (response.status == 200) {
                    item.itemsst = response.data;
                    item.DangerSSTDetail = response.data.DangerSSTDetail || [];
                    item.DangerSSTRecommendation = response.data.DangerSSTRecommendation || [];
                  }
                })
              }

              if (typeof item.itemsFunciones === "string") {
                try {
                  item.itemsFunciones = JSON.parse(item.itemsFunciones);
                } catch (e) {
                  console.error("Error al parsear item.itemsFunciones:", e);
                }
              } else if (
                typeof item.itemsFunciones !== "object" ||
                item.itemsFunciones === null
              ) {
                // console.error(
                //   "item.itemsFunciones no es un JSON válido o no es una cadena JSON."
                // );
              }

              items.push(item);
            }
          });
      }
      this.PdfSelectedContract = items;
      this.dialog = false;
      this.dialogPdfContractMasivo = true;

      if (this.missingSSTRecords.length > 0) {
        this.dialogMissingSST = true; // Mostrar modal si hay registros sin SST
      }

      console.log("this.missingSSTRecords", this.missingSSTRecords);
    },
    viewContract(value) {
      if (value == null) {
        this.$fun.alert("Seleccione un contrato para visualizar", "warning");
      }
      if (value.PrsDocumentNumber == null) {
        this.$fun.alert("Seleccione un contrato para visualizar", "warning");
        return;
      }

      if (value.PrsDocumentNumber.length > 3) {
        let obj = {
          TypePerson: this.typeperson,
          PrsDocumentNumber: value.PrsDocumentNumber,
          UsrID: this.$fun.getUserID(),
          IsValidation: this.isValidation,
        };

        Promise.all([
          _sContractService.workerPerson(
            this.item.WkrID,
            this.item.AgtID,
            this.$fun.getUserID()
          ),
          Service.personatusearch(obj, this.$fun.getUserID()),
        ]).then(([res1, res2]) => {
          if (res1.status == 200) {
            this.dataWork = res1.data;
            this.item = this.dataWork;
          }

          if (res2.status == 200) {
            if (res2.data != null && res2.data.StatusPerson != 1) {
              this.personEdit = Object.assign(res2.data, {});
              this.showView = true;

              this.$nextTick(() => {
                this.$refs.textEditPerson.search();
              });
              this.$emit("returnPerson", this.personEdit);
              if (res2.data.StatusPerson == 2) {
                this.marker = true;
                this.color = "orange darken-2";
              } else this.marker = false;
            } else {
              this.$emit("returnPerson", null);
              this.nofoundPerson();
              this.dialogAddPerson = true;
            }
          }
        });
      }
    },
    printContract(row) {
      if (!this.$fun.isAdmin()) {
        if (
          row.TypePosition == 1 ||
          row.TypePosition == 2 ||
          row.TypePosition == 3
        ) {
          if (!this.$fun.hasSpecialPermission("Nivel_1")) {
            this.$fun.alert(
              "Usted no tiene permisos para imprimir un contrato de personal ejecutivo",
              "warning"
            );
            return;
          }
        }

        if (
          row.TypePosition == 4 ||
          row.TypePosition == 5 ||
          row.TypePosition == 6
        ) {
          if (!this.$fun.hasSpecialPermission("Nivel_2")) {
            this.$fun.alert(
              "Usted no tiene permisos para imprimir un contrato de personal administrativo",
              "warning"
            );
            return;
          }
        }

        if (
          row.TypePosition == 7 ||
          row.TypePosition == 8 ||
          row.TypePosition == 9
        ) {
          if (!this.$fun.hasSpecialPermission("Nivel_3")) {
            this.$fun.alert(
              "Usted no tiene permisos para imprimir un contrato de personal operativo",
              "warning"
            );
            return;
          }
        }

        if (
          !this.$fun
            .getSecurity()
            .PayrollsAllow.some(
              (payroll) => payroll.TypePayroll === row.TypePayroll
            )
        ) {
          this.$fun.alert(
            "Usted no tiene permisos para imprimir este tipo de contrato",
            "warning"
          );
          return;
        }
      }

      _sContractService
        .searchContract(row.AgtID, this.$fun.getUserID())
        .then((r) => {
          if (r.status == 200) {
            const item = r.data[0];
            if (typeof item.itemsFunciones === "string") {
              try {
                item.itemsFunciones = JSON.parse(item.itemsFunciones);
              } catch (e) {
                console.error("Error al parsear item.itemsFunciones:", e);
              }
            } else if (
              typeof item.itemsFunciones !== "object" ||
              item.itemsFunciones === null
            ) {
              console.error(
                "item.itemsFunciones no es un JSON válido o no es una cadena JSON."
              );
            }
            this.PdfSelectedContract = item;
            if (item.TypeContract == 11) {
              this.dialogPdfContractPorTemporada = true;
            } else if (item.TypeContract == 3) {
              this.dialogPdfContractPorIncrementoActividades = true;
            } else if (item.TypeContract == 9) {
              this.dialogPdfContractPorServicioEspecifico = true;
            }
          }
        });
    },
    deleteItem() {
      console.log("deleteItem", this.itemCheck);
      this.itemCheck = [];
    }
  },

  created() {
    this.initialize();
  },
  mounted() {
    this.$fun.hasSpecialPermission("Nivel_1");
  },
};
</script>

<style>
.scrollable {
  max-height: 450px; /* Ajusta según sea necesario */
  overflow-y: auto; /* Habilita el scroll vertical */
}

.v-dialog--fullscreen {
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.6);
  /* background-color: rgba(33, 33, 33, 0.46); */
}
.custom-item {
  min-height: 40px; /* Reducir tamaño */
  border-radius: 10px; /* Bordes redondeados */
  transition: background-color 0.2s;
}

.selected-item {
  background-color: rgba(58, 158, 241, 0.2) !important; /* Color de fondo al seleccionar */
  margin-bottom: 3px;
}
</style>